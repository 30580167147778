
import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import { IUserProfileCreate } from '@/interfaces/users'
import userModule from '@/store/user'

extend('confirmed', { ...confirmed, message: 'Passwords do not match' })

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class UserCreateView extends Vue {
  public fullName = ''
  public email = ''
  public isActive = true
  public setPassword = false
  public password1 = ''
  public password2 = ''
  public role = -1

  public async mounted() {
    await Promise.all([userModule.actionLoadUsers()])
    this.reset()
  }

  public reset() {
    this.password1 = ''
    this.password2 = ''
    this.fullName = ''
    this.email = ''
    this.isActive = true
  }

  public cancel() {
    this.$router.back()
  }

  public async submit() {
    const updatedProfile: IUserProfileCreate = {
      email: this.email
    }
    if (this.fullName) {
      updatedProfile.full_name = this.fullName
    }
    if (this.email) {
      updatedProfile.email = this.email
    }
    updatedProfile.is_active = this.isActive
    updatedProfile.is_superuser = false
    updatedProfile.password = this.password1

    await userModule.actionCreateUser(updatedProfile)
    this.$router.push({ name: 'admin-users-all' })
  }
}
